<template>
  <div :key="$route.meta.name" class="fr-page" :data-index="4">
    <slot />
  </div>
</template>

<style lang="sass">

// #NEO-TAG
.neo
  .fr-page
    @apply p-3 pt-0 lg:p-3 lg:pt-0

// #CHOWEY-TAG
.chowey
  .fr-page
    @apply p-3 pt-0 lg:p-3 lg:pt-0
</style>
